<!--
	This is the sign up page, it uses the dashboard layout in:
	"./layouts/Default.vue" .
 -->

<template>
  <div>
    <!-- Sign Up Image And Headings -->
    <div class="sign-up-header" style="background-image: url('images/bg-signup.jpg')">
      <div class="content">
        <h1 class="mb-5 font-regular">盈标网内容管理系统</h1>
      </div>
    </div>
    <!-- / Sign Up Image And Headings -->
    <!-- Sign Up Form -->
    <a-card :bordered="false" class="card-signup header-solid h-full" :bodyStyle="{padding: '32px'}">
      <div class="mb-24">
        <h3 class="mb-5 font-regular">欢迎登录</h3>
      </div>
      <a-form
          id="components-form-demo-normal-login"
          :form="form"
          class="login-form"
          @submit="handleSubmit"
      >
        <a-form-item>
          <a-input
              v-decorator="[
                  'account',
                  { rules: [{ required: true, message: '请输入账号' }] },
                  ]"
              placeholder="账号"
          />
        </a-form-item>
        <a-form-item>
          <a-input
              v-decorator="[
						'password',
						{ rules: [{ required: true, message: '请输入密码!' }] },
						]"
              type="password"
              placeholder="密码"
          >
          </a-input>
        </a-form-item>
        <a-form-item class="mb-10">
          <a-switch v-model="rememberMe"/> 记住账号
        </a-form-item>
<!--        <a-form-item class="mb-10">-->
<!--          <a-checkbox-->
<!--              v-decorator="[-->
<!--						'remember',-->
<!--						{-->
<!--							valuePropName: 'checked',-->
<!--							initialValue: true,-->
<!--						},-->
<!--						]"-->
<!--          >-->
<!--            I agree the <a href="#" class="font-bold text-dark">Terms and Conditions</a>-->
<!--          </a-checkbox>-->
<!--        </a-form-item>-->
        <a-form-item>
          <a-button type="primary" block html-type="submit" class="login-form-button" :loading="formLoading">登录</a-button>
        </a-form-item>
      </a-form>
<!--      <p class="font-semibold text-muted text-center">Already have an account? <router-link to="/sign-in" class="font-bold text-dark">Sign In</router-link></p>-->
    </a-card>
    <!-- / Sign Up Form -->

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: 'normal_login' }),
      formLoading: false,
      rememberMe: false,
    }
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),
    // Handles input validation after submission.
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if ( !err ) {
          console.log('Received values of form: ', values)
          this.doLogin(values)
        }
      });
    },
    async doLogin(values) {
      this.formLoading = true;
      const { success } = await this.signIn(values)
      this.formLoading = false;
      if(success) {
        this.$router.replace('/')
      }
    }
  },
  mounted() {
    // this.form.setFieldsValue({
    //   account: 'Panda',
    //   password: '123456',
    // })
  }
}
</script>

<style lang="scss">
</style>
